import React from "react";
import { Link } from "gatsby";

const IndexLogoCloud = () => {
  return (
    <>
      {/* Split brand panel */}
      <div className="relative bg-white">
        <div className="absolute inset-0" aria-hidden="true">
          <div className="absolute inset-y-0 right-0 w-1/2 bg-gradient-to-r from-teal-500 to-cyan-600" />
        </div>
        <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-2 lg:px-8">
          <div className="bg-white py-16 px-4 sm:py-24 sm:px-6 lg:px-0 lg:pr-8">
            <div className="max-w-lg mx-auto lg:mx-0">
              <h2 className="text-base font-semibold tracking-wide text-cyan-600 uppercase">
                Atlassian Solution Partner
              </h2>
              <p className="mt-2 text-2xl font-extrabold text-gray-900 sm:text-3xl">
                {/* We're a small but highly motivated team to fully support your digital transformation using Atlassian products. */}
                Top-tier help with your Atlassian products and apps!
              </p>
              <dl className="mt-12 space-y-10">
                <div className="flex">
                  <div className="flex-shrink-0 h-12 w-12 bg-gradient-to-r from-teal-500 to-cyan-600 rounded-md shadow-lg flex items-center justify-center">
                    {/* Heroicon: chat-alt2 */}
                    <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
                    </svg>
                  </div>
                  <div className="ml-4">
                    <dt className="text-lg leading-6 font-medium text-gray-900">
                      Consulting and Implementation
                    </dt>
                    <dd className="mt-2 text-base text-gray-500">
                      Overcome the challenges of your Atlassian products with our advice and guidance.
                    </dd>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex-shrink-0 h-12 w-12 bg-gradient-to-r from-teal-500 to-cyan-600 rounded-md shadow-lg flex items-center justify-center">
                    {/* Heroicon: code */}
                    <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
                    </svg>
                  </div>
                  <div className="ml-4">
                    <dt className="text-lg leading-6 font-medium text-gray-900">
                      Atlassian Cloud Apps
                    </dt>
                    <dd className="mt-2 text-base text-gray-500">
                      Dumblefy helps you build cloud apps using Forge and Connect to fully support your high-performing teams.
                    </dd>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex-shrink-0 h-12 w-12 bg-gradient-to-r from-teal-500 to-cyan-600 rounded-md shadow-lg flex items-center justify-center">
                    {/* Heroicon: academic-cap */}
                    <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                      <path d="M12 14l9-5-9-5-9 5 9 5z" />
                      <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
                    </svg>
                  </div>
                  <div className="ml-4">
                    <dt className="text-lg leading-6 font-medium text-gray-900">
                      Training and Workshops
                    </dt>
                    <dd className="mt-2 text-base text-gray-500">
                      Individual and group trainings to help your teams get the most out of their products.
                    </dd>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex-shrink-0 h-12 w-12 bg-gradient-to-r from-teal-500 to-cyan-600 rounded-md shadow-lg flex items-center justify-center">
                    {/* Heroicon: credit-card */}
                    <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
                    </svg>
                  </div>
                  <div className="ml-4">
                    <dt className="text-lg leading-6 font-medium text-gray-900">
                      Licenses
                    </dt>
                    <dd className="mt-2 text-base text-gray-500">
                      As an official Atlassian partner we can assist you with buying and optimizing your licenses, renewals, consolidations and also (monthly) payments in euro.
                    </dd>
                  </div>
                </div>
              </dl>
            </div>
          </div>
          <div className="bg-gradient-to-r from-teal-500 to-cyan-600 py-16 px-4 sm:py-24 sm:px-6 lg:bg-none lg:flex lg:items-center lg:justify-end lg:px-0 lg:pl-8">
            <div className="max-w-lg mx-auto w-full space-y-8 lg:mx-0">
              <ul className="bg-cyan-700 bg-opacity-50 rounded sm:grid sm:grid-cols-2 sm:grid-rows-4 sm:grid-flow-col">
                <li className="py-4 px-4 flex items-center text-base text-white">
                  {/* Heroicon name: check */}
                  <svg className="h-6 w-6 text-cyan-200" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span className="ml-3">Atlassian Cloud</span>
                </li>
                <li className="border-t border-cyan-300 border-opacity-25 py-4 px-4 flex items-center text-base text-white">
                  {/* Heroicon name: check */}
                  <svg className="h-6 w-6 text-cyan-200" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span className="ml-3">Jira Software</span>
                </li>
                <li className="border-t border-cyan-300 border-opacity-25 py-4 px-4 flex items-center text-base text-white">
                  {/* Heroicon name: check */}
                  <svg className="h-6 w-6 text-cyan-200" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span className="ml-3">Confluence</span>
                </li>
                <li className="border-t border-cyan-300 border-opacity-25 py-4 px-4 flex items-center text-base text-white">
                  {/* Heroicon name: check */}
                  <svg className="h-6 w-6 text-cyan-200" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span className="ml-3">Jira Service Management</span>
                </li>
                <li className="border-t border-cyan-300 border-opacity-25 py-4 px-4 flex items-center text-base text-white sm:border-t-0 sm:border-l">
                  {/* Heroicon name: check */}
                  <svg className="h-6 w-6 text-cyan-200" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span className="ml-3">Migrations</span>
                </li>
                <li className="border-t border-cyan-300 border-opacity-25 py-4 px-4 flex items-center text-base text-white sm:border-l">
                  {/* Heroicon name: check */}
                  <svg className="h-6 w-6 text-cyan-200" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span className="ml-3">Bitbucket</span>
                </li>
                <li className="border-t border-cyan-300 border-opacity-25 py-4 px-4 flex items-center text-base text-white sm:border-l">
                  {/* Heroicon name: check */}
                  <svg className="h-6 w-6 text-cyan-200" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span className="ml-3">Trello</span>
                </li>
                <li className="border-t border-cyan-300 border-opacity-25 py-4 px-4 flex items-center text-base text-white sm:border-l">
                  {/* Heroicon name: check */}
                  <svg className="h-6 w-6 text-cyan-200" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span className="ml-3">Atlassian Ecosystem</span>
                </li>
              </ul>
              <Link to="/contact/" className="w-full border border-transparent rounded-md py-4 px-8 flex items-center justify-center text-lg leading-6 font-medium md:px-10 bg-cyan-50 text-cyan-700 hover:bg-cyan-100 hover:text-cyan-900">
                What can we help you with?
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default IndexLogoCloud