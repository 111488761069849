import React from "react";
import LogoVDABImage from "../images/logo-VDAB.svg";
import LogoInetumRealdolmenImage from "../images/logo-Inetum-Realdolmen.svg";
import LogoWhitemilkImage from "../images/logo-Whitemilk.png";
import LogoSkyluxImage from "../images/logo-Skylux.png";

const IndexLogoCloud = () => {
  return (
    <div className="bg-cyan-100 bg-opacity-25">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-cyan-900">
          Some of the places we've worked
        </h2>
        <div className="flow-root mt-8 lg:mt-10">
          <div className="-mt-4 -ml-8 flex flex-wrap justify-between lg:-ml-4">
            <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
              <img className="h-12" src={LogoVDABImage} alt="VDAB" />
            </div>
            <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
            </div>
            <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
              <img className="h-12" src={LogoInetumRealdolmenImage} alt="Inetum-Realdolmen" />
            </div>
            <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
              <img className="h-12" src={LogoWhitemilkImage} alt="Whitemilk" />
            </div>
            <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
              <img className="h-12" src={LogoSkyluxImage} alt="Skylux" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IndexLogoCloud