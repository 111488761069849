import React from "react";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import IndexHero from "../components/IndexHero";
import IndexSplitPanel from "../components/IndexSplitPanel";
import IndexLogoCloud from "../components/IndexLogoCloud";
import IndexCTA from "../components/IndexCTA";

const IndexPage = () => (
  <>
    <SEO title={"Atlassian Solution Partner"} />
    <Layout>
      <IndexHero />
      <IndexSplitPanel />
      <IndexLogoCloud />
      <IndexCTA />
    </Layout>
  </>
)

export default IndexPage